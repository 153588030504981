import { createStandaloneToast } from "@chakra-ui/react";
import jwtDecode from "jwt-decode";
import { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";

const AuthRoute = lazy(() => import("./routes/AuthRoute"));
const UnAuthRoute = lazy(() => import("./routes/UnAuthRoute"));
const { ToastContainer } = createStandaloneToast();

function App() {
  let token = localStorage.getItem("suaTk");
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      const expiryDate = new Date(decoded.exp * 1000);
      if (new Date() > expiryDate) {
        localStorage.removeItem("suaTk");
        setAuthorized(false);
      } else {
        setAuthorized(true);
      }
    }
  }, [token]);
  return (
    <Suspense fallback={<p></p>}>
      {authorized ? <AuthRoute /> : <UnAuthRoute />}
      <ToastContainer />
    </Suspense>
  );
}

export default App;
