import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  ChakraProvider,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react";
import { UserProfileContextProvider } from "./context/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Button = defineStyleConfig({
  baseStyle: {
    boxShadow: "none",
    _hover: { background: "#2B5363 " },
    _focus: { background: "#2B5363" },

    alignSelf: "center",
    bg: "#2B5363",
    color: "#fff",
    fontWeight: "normal",
    width: "300px",
    py: "25px",
  },
  defaultProps: {
    variant: null, // this makes custom input background color work
    _hover: { background: "#2B5363" },
    _focus: { background: "#2B5363" },
  },
});
const Heading = defineStyleConfig({
  baseStyle: {
    fontFamily: "Trenda-bold",
  },
});
const FormLabel = defineStyleConfig({
  baseStyle: {
    // fontFamily: "Trenda-bold",
    fontWeight: "600",
    fontSize: "1.1em",
  },
});

const Input = defineStyleConfig({
  baseStyle: (props) => ({
    field: {
      border: "1.2px solid #989898",
      borderRadius: "20px",
      // background: props.colorMode === "light" ? "#fff" : "#010000",
      boxShadow: "none",
      _placeholder: { fontSize: ".7em", color: "#C8C8C8" },
    },
  }),
  defaultProps: {
    variant: null, // this makes custom input background color work
  },
});

const theme = extendTheme({
  components: {
    Button,
    Heading,
    Input,
    FormLabel,
  },
  styles: {
    global: (props) => ({
      // styles for the `body`
      body: {
        fontFamily: "Trenda-regular",
        fontSize: ".8em",
        // background: props.colorMode === "light" ? "#F3F3F3" : "#09192E",
      },
      h2: {
        fontSize: "2em !important",
        color: "#3D3D3D",
        fontFamily: "Trenda-bold",
      },

      input: {
        padding: "22px 0px",
        borderRadius: "20px",

        _placeholder: {
          // color: props.colorMode === "light" ? "#cccccc" : "grey",
        },
      },
    }),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <UserProfileContextProvider>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID}
        >
          <App />
        </GoogleOAuthProvider>
      </UserProfileContextProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
