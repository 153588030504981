import { createContext, useState } from "react";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  const [state, setState] = useState({ toggleSideBar: false });

  const dispatch = (incoming) => {
    setState((prev) => ({ ...prev, ...incoming }));
  };

  return (
    <UserProfileContext.Provider value={{ dispatch, state }}>
      {children}
    </UserProfileContext.Provider>
  );
};
